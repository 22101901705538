import axios from 'axios';
import { singular, plural } from 'pluralize';
const cache = {}; // api cache
const headers = {};

function setHeaders(client) {
    _.chain(headers)
        .toPairs()
        .map(([k, v]) => client.defaults.headers.common[k] = v)
        .value()
}

export default function initApi(env) {

    const client = axios.create({
        baseURL: env.rootPath,
    });

    client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    client.interceptors.response.use(undefined, (err) => {
        let config = err.config;
        // If config does not exist or the retry option is not set, reject
        if(!config || !config.retry || !_.includes(config.retryError, err.response.status)) {
            return Promise.reject(err);
        }

        // Set the variable for keeping track of the retry count
        config.__retryCount = config.__retryCount || 0;

        // Check if we've maxed out the total number of retries
        if(config.__retryCount >= config.retry) {
            // Reject with the error
            return Promise.reject(err);
        }

        // Increase the retry count
        config.__retryCount += 1;

        // Create new promise to handle exponential backoff
        let backoff = new Promise(function(resolve) {
            setTimeout(function() {
                resolve();
            }, config.retryDelay || 1);
        });

        // Return the promise in which recalls axios to retry the request
        return backoff.then(function() {
            return axios(config);
        });
    });

    const apiConfig = _.get(env, 'routes.endpoints', {}); // api config
    const apiRoot = ['api', _.get(env, 'apiPrefix')].filter(Boolean).join('/').replace(/^\//, '');

    // utils
    const getRefType = (path) => {
        const pieces = path.match(/(\w+)\/_ref_/);
        return pieces ? pieces[1] : null;
    }

    const serializeQuery = (queryObject) => {
        return _.toPairs(queryObject)
            .reduce((tmp, keyValuePair) => {
                const [key, value] = keyValuePair;

                return [...tmp, value ? `${key}:${value}` : key]
            }, [])
            .join(';')
    }

    const deserializeQuery = (queryString) => {
        return _.chain(queryString)
            .split(/[;]/)
            .filter(Boolean)
            .reduce((tmp, keyValueString) => {
                const [key, value] = keyValueString.split(/[\=\:]/);
                if (!key) return tmp;

                return { ...tmp, [key]: value || null }
            }, {})
            .value();
    }

    const mergeQuery = (configParams, query = {}, config = {}) => {
        const configFilter = _.get(configParams, 'filter', '');
        const queryFilter = _.get(query, 'filter', '');

        let resultFilter = '';
        resultFilter = serializeQuery(
            _.assign({}, deserializeQuery(configFilter), deserializeQuery(queryFilter))
        )

        if (resultFilter) {
            query.filter = resultFilter;
        }

        const resultQuery = _.assign({}, configParams, query);
        return resultQuery;
    }

    // functionalities
    return {
        client,
        setHeader(key, value) {
            headers[key] = value;
        },
        getUser() {
            setHeaders(client);
            return client.get(`/${apiRoot}/me`)
                .then(res => res.data);
        },
        logout() {
            setHeaders(client);
            return client.post('/admin/logout');
        },
        getData(path, query, options = {}) {
            setHeaders(client);
            const endpointConfig = _.chain(apiConfig)
                .toPairs()
                .find(([pattern, config]) => {
                    return path === pattern || path.match(new RegExp(pattern));
                })
                .get('[1]', {})
                .value();

            const refType = getRefType(path);
            if (refType && cache[refType]) {
                if (cache[refType] instanceof Promise) {
                    return cache[refType];
                } else {
                    return Promise.resolve(cache[refType])
                }
            }

            const params = _.chain(mergeQuery(endpointConfig.params, query, endpointConfig))
                .toPairs()
                .filter('1')
                .map(([key, val]) => {
                    return [
                        decodeURIComponent(key),
                        decodeURIComponent(val).replace(/\+/g, ' '),
                    ]
                })
                .fromPairs()
                .value();

            path = _.trimStart(path, '/');
            let req = client.get(`/${apiRoot}/${path}`, { ...options, params })
                .then(res => {
                    // cache refs data
                    if (refType) {
                        cache[refType] = res.data;
                    }
                    return res.data
                });

            if (refType) {
                cache[refType] = req;
            }

            return req;
        },
        postData(path, query, data) {
            setHeaders(client);
            path = _.trimStart(path, '/');
            return client.post(`/${apiRoot}/${path}`, data, {
                    params: query
                })
                .then(res => {
                    if (res.data.type) {
                        const refType = plural(res.data.type);
                        cache[refType] = null;
                    }
                    return res.data;
                });
        },
        patchData(path, query, data) {
            setHeaders(client);
            path = _.trimStart(path, '/');
            return client.patch(`/${apiRoot}/${path}`, data, {
                    params: query
                })
                .then(res => {
                    return res.data;
                });
        },
        putData(path, query, data) {
            setHeaders(client);
            path = _.trimStart(path, '/');
            return client.put(`/${apiRoot}/${path}`, data, {
                    params: query
                })
                .then(res => {
                    if (res.data.type) {
                        const refType = plural(res.data.type);
                        cache[refType] = null;
                    }
                    return res.data;
                });
        },
        delData(path, query) {
            setHeaders(client);
            path = _.trimStart(path, '/');
            return client.delete(`/${apiRoot}/${path}`, {
                    params: query
                })
                .then(res => {
                    if (res.data.type) {
                        const refType = plural(res.data.type);
                        cache[refType] = null;
                    }
                    return res.data;
                });
        },
        uploadData(path, query, data) {
            setHeaders(client);
            const formData = new FormData();
            _.toPairs(data).forEach(([field, value]) => {
                formData.append(field, value);
            });

            path = _.trimStart(path, '/');
            return client.post(`/${apiRoot}/${path}`, formData)
                .then(res => res.data);
        },
        runCommand(command, args, opts, file = null) {
            setHeaders(client);
            if (file) {
                const formData = new FormData();

                let argments = _.chain(args)
                    .toPairs()
                    .map(([key, value]) => [`args[${key}]`, value])
                    .fromPairs()
                    .value();

                let options = _.chain(opts)
                    .toPairs()
                    .map(([key, value]) => [`opts[${key}]`, value])
                    .fromPairs()
                    .value();

                formData.append('file', file);

                return client.post(`/${apiRoot}/command/${command}`, formData, {
                    params: {
                        ...argments,
                        ...options,
                    }
                });
            }

            return client.post(`/${apiRoot}/command/${command}`, {
                args, opts
            });
        },
        utils: {
            serializeQuery,
            deserializeQuery,
        },
    }
}
