import _ from 'lodash';
import V from 'voca';
import M from 'moment';
import Promise from 'bluebird';
import axios from 'axios';
import URI from 'urijs';
import { singular, plural } from 'pluralize';
import sanitizeHtml from 'sanitize-html';
import initApi from './api';

export default function initHelpers(env = {}) {
    // property
    let router = {};

    let config = {};

    const register = {}; // listener register

    const api = initApi(env);

    const uriFormat = (val, stripQuery = true) => {
        try {
            const path = URI(val).path();
            const query = URI(val).query();

            let pieces = path.split('/');
            const prefixes = ['api', _.get(env, 'apiPrefix')];

            const endpoint = pieces
                .reduce((tmp, piece, idx) => {
                    if (_.includes(prefixes, piece)) {
                        return [];
                    }

                    return [...tmp, piece];

                }, [])
                .filter(Boolean).join('/').replace(/^\//, '');
            return '/' + endpoint + (stripQuery ? '' : `?${query}`);

        } catch (e) {
            return '';
        }
    }

    const parseTreeStructure = (data) => {

        const listData = _.chain(data)
            .map(({ id, name, parentId }) => ({
                id, name,
                pid: parentId,
                dragDisabled: false,
                editable: false,
                isLeaf: false,
                children: [],
            })).value();

        const mapData = _.chain(listData)
            .map(item => [item.id, item])
            .fromPairs()
            .value();

        return _.chain(listData)
            .map(item => {
                if (item.pid) {
                    mapData[item.pid].children.push(item);
                }
                return item;
            })
            .filter(item => !item.pid)
            .value();
    }

    const getMessage = (response, method) => {
        const pieces = String(response.type).split('.');
        const shortKey = `${pieces.join('.')}.${method}`;
        const fullKey = `${pieces[0]}.${method}`;

        return config.notifications[fullKey] || config.notifications[shortKey] || config.notifications.default(response.type, method);
    }

    const getErrorMessage = (error, method) => {
        console.log(error);
        let message;
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            message = error.response.data.message;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
            message = 'Request error';
        } else {
            // Something happened in setting up the request that triggered an Error
            message = error.message;
        }
        return message;
    }

    const initials = (phrase) => {
        return phrase.split(/[\s]+/).map(word => word[0]).join('').toUpperCase();
    }

    const formatString = (value, format = null) => {
        let string = String(value || '');
        if (!format) {
            return V.chain(string).replace(/[\_]/g, ' ').value();
        }
        if (!_.isArray(format)) {
            return V.chain(string).replace(/[\_]/g, ' ').sprintf(format).value();
        }

        format.forEach(transform => {
            switch (transform) {
                case 'datetime':
                    return string = M(string).format('DD MMM YYYY HH:mm:ss');
                case 'datetime_ampm':
                    return string = M(string).format('DD MMM YYYY h:mm:ss pm');
                case 'date':
                    return string = M(string).format('DD MMM YYYY');
                case 'date_short':
                    return string = M(string).format('DD/MM/YYYY');
                case 'time':
                    return string = M(string).format('HH:mm:ss');
                case 'time_ampm':
                    return string = M(string).format('h:mm:ss pm');
                case 'titleCase':
                    return string = V.chain(string).replace(/[\_]/g, ' ').titleCase().value();
                case 'capFirst':
                    return string = String(string).charAt(0).toUpperCase() + String(string).slice(1);
                case 'truncate':
                    return string = _.chain(string).split(' › ').last().value();
                case 'singular':
                    return string = V.chain(singular(string)).replace(/[\_]/g, ' ').value();
                case 'plural':
                    return string = V.chain(plural(string)).replace(/[\_]/g, ' ').value();
                case 'nl2br':
                    return string = V.chain(string).replace(/(?:\r\n|\r|\n)/g, '<br>').value();
                case 'zerofill':
                    return string = String(string).padStart(6, '0');
                case 'shorten':
                    return string = _.chain(string).split(' › ').map((part) => initials(part)).join(' › ').value();
                case 'money':
                    let amount = Math.round(Number(string) * 100) / 100;
                    return string = V.sprintf('$%.2f', amount).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                case 'sanitize':
                    return string = sanitizeHtml(string, {
                        allowedTags: [
                            'b', 'i', 'em', 'strong',
                            'a', 'p', 'span', 'br',
                        ],
                        allowedAttributes: {
                            'a': [ 'href' ]
                        },
                    });
                default:
                    return string = V.chain(string).replace(/[\_]/g, ' ').value();
            }
        });

        return string;
    }

    const dispatchEvent = (eventName, event) => {
        // console.log(`dispatching ${eventName}`);
        const handlers = _.chain(register)
            .toPairs()
            .find(([name, handlers]) => name == eventName)
            // get handlers
            .get('[1]', {})
            .values()
            .value()
            .forEach(handler => handler(event))
    }

    const subscribeEvent = (eventName, key, handler) => {
        // console.log(`subscribing ${eventName} ${key}`);
        register[eventName] = _.assign(register[eventName], { [key]: handler });
    }

    const unsubscribeEvent = (eventName, key) => {
        // console.log(`unsubscribing ${eventName} ${key}`);
        register[eventName] = _.omit(register[eventName], [key]);
    }

    const getSetting = (key) => {
        return _.get(window.env, key);
    }

    const setRouter = (newRouter) => {
        router = newRouter;
    }

    const getRouter = () => {
        return router;
    }

    const setConfig = (newConfig) => {
        config = newConfig;
    }

    const getConfig = (path = null, def = null) => {
        if (path) {
            return _.get(config, path, def);
        }
        return config;
    }

    let helpers = {}

    const setHelper = (name, method) => {
        helpers[name] = method;
    }

    const getImagePath = (image, variant = null, params = {}) => {
        const imageId = _.get(image, 'id', 'null');
        const useWebp = getSetting('USE_WEBP');
        let imageExt = _.get(image, 'extension', 'jpg');
        if(useWebp && imageExt != 'svg') {
            imageExt = 'webp';
        }
        let imageVar = variant;
        let imageSecured = _.get(image, 'secured');
        let imagePath = _.get(image, 'secured') ? (`/secured/${image.storage}`) : '/asset';

        if (_.includes(['webp', 'jpeg', 'jpg', 'png', 'svg', 'gif'], imageExt)) {
            // do nothing
        } else if (_.includes(['pdf', 'doc', 'docx', 'xls', 'xlsx', 'mp4', 'webm'], imageExt)) {
            imageVar = 'Cover';
            imageExt = 'jpg';
        } else {
            return null;
        }

        let imageUrl = _.chain([imagePath, imageId, imageVar])
            .filter().join('/')
            .concat(imageExt)
            .filter().join('.')
            .value();

        params = new URLSearchParams(params);
        let imageToken = _.get(image, 'token', null);
        let imageSecuredToken = _.get(image, 'secured_token', null);
        if (imageSecured) {
            imageUrl = `${imageUrl}?asset_token=${imageSecuredToken}&${params.toString()}`;
        } else if (imageToken) {
            imageUrl = `${imageUrl}?token=${imageToken}&${params.toString()}`;
        } else {
            imageUrl = `${imageUrl}?${params.toString()}`;
        }

        return imageUrl;
    }

    const getImagePathBasic = (imageId, variant = null, ext = 'webp') => {
        let imageExt = ext;
        let imageVar = variant;
        let imagePath = '/asset';
        let imageUrl = _.chain([imagePath, imageId, imageVar])
            .filter().join('/')
            .concat(imageExt)
            .filter().join('.')
            .value();
        return imageUrl;
    }

    const getAssetPath = (asset, download = false, params = {}) => {
        const assetId = _.get(asset, 'id', 'null');
        // let assetName = _.get(asset, 'name', 'origin');
        let assetExt = _.get(asset, 'extension', 'dat');
        let assetSecured = _.get(asset, 'secured');
        let assetPath = assetSecured ? (`/secured`) : '/asset'


        if (!assetId) {
            return '#';
        }

        let token = _.get(asset, 'token');
        let assetSecuredToken = _.get(asset, 'secured_token');

        return _.chain([assetPath, assetId])
            .filter()
            .join('/')
            .concat(assetExt)
            .join('.')
            .value() + '?' + _.chain([
                assetSecured ? `asset_token=${assetSecuredToken}` : null,
                token ? `token=${token}` : null,
                download ? `download` : null,
            ]).filter().join('&');

    }

    const getGeolocation = (address) => {

        const apiKey = window.env.GOOGLE_MAP_KEY;
        const params = new URLSearchParams();
        params.set('key', apiKey);
        params.set('address', address);
        params.set('sensor', false);

        return axios.get(`https://maps.google.com/maps/api/geocode/json?${params.toString()}`)
            .then(res => {
                if (_.get(res, 'data.status') == 'OK') {
                    return _.get(res, ['data', 'results', '0', 'geometry', 'location']);
                }

                throw Error(_.get(res, 'data.error_message'));
            })
    }

    const requiredPhone = (value, data, required = false) => {

        if (required) {
            // required needs to have a value
            if (_.isUndefined(value) || _.isNull(value) || value == '') {
                return false;
            }
        } else {
            // not required but empty, dont validate
            if (_.isUndefined(value) || _.isNull(value) || value == '') {
                return true;
            }
        }

        // further validations
        let stripped = value.replace(/ /g,'');
        if (stripped.length < 9) {
            return false;
        }
        let regex = /^(?=.*[0-9])[ +0-9]+$/;
        return !_.isNull(_.invoke(value, 'match', regex));
    }

    helpers = {
        api, uriFormat, parseTreeStructure, getImagePath, getAssetPath, getImagePathBasic,
        getSetting, getMessage, getErrorMessage, formatString,
        dispatchEvent, subscribeEvent, unsubscribeEvent,
        setRouter, getRouter, setConfig, getConfig, setHelper,
        getGeolocation, requiredPhone,
    }

    // helper getter
    return (path = null) => {
        if (!path) return helpers;

        return _.get(helpers, path);
    }
}
