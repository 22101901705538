export default {
    items: [
        {
            name: 'Dashboard',
            type: 'dashboard',
            route: 'dashboard',
            iconClasses: ['font-icon', 'font-icon-dashboard'],
            iconClass: 'material-icons',
            iconName: 'dashboard',
            color: 'navy',
        },
        {
            name: 'Content',
            children: [
                { name: 'Pages', route: 'pages', type: 'page', shortcut: 'page/_' },
                { name: 'Panel library', route: 'panels', type: 'panel', shortcut: 'panel/_' },
                { name: 'Quick Links', route: 'quick_links', type: 'quick_link', shortcut: 'quick_link/_' },
            ],
            iconClass: 'material-icons',
            iconName: 'content_copy',
            color: 'navy',
        },
        {
            name: 'Assets',
            route: 'assets',
            type: 'asset',
            iconClass: 'material-icons',
            iconName: 'photo_album',
            color: 'navy',
        },
        {

            name: 'News',
            children: [
                { name: 'Posts', route: 'news_posts', type: 'news_post' },
                { name: 'Categories', route: 'news_categories', type: 'news_category' },
            ],
            iconClass: 'material-icons',
            iconName: 'perm_media',
            color: 'navy',
        },
        {
            name: 'Videos',
            route: 'videos',
            type: 'video',
            iconClass: 'material-icons',
            iconName: 'video_library',
            color: 'navy',
        },
        {
            name: 'Products',
            children: [
                { name: 'Products', route: 'products', type: 'product' },
                { name: 'Categories', route: 'categories', type: 'category' },
            ],
            iconClass: 'material-icons',
            iconName: 'store',
            color: 'navy',
        },
        {
            name: 'Dealers',
            type: 'dealer',
            route: 'dealers',
            iconClass: 'material-icons',
            iconName: 'people',
            color: 'navy',
        },
        {
            name: 'Customers',
            type: 'customer',
            route: 'customers',
            iconClass: 'material-icons',
            iconName: 'perm_contact_calendar',
            color: 'navy',
        },
        {
            name: 'Enquiries',
            children: [
                { name: 'Enquiries', route: 'enquiries', type: 'enquiry' },
                { name: 'Subscribers', route: 'subscribers', type: 'subscriber' },
            ],
            iconClass: 'material-icons',
            iconName: 'forum',
            color: 'navy',
        },
        {
            name: 'Warranty',
            children: [
                { name: 'Warranties', route: 'warranties', type: 'warranty' },
                { name: 'Warranty periods', route: 'warranty_period', type: 'warranty_period' },
                { name: 'Warranty reminders', route: 'warranty_reminder', type: 'warranty_reminder' },
                { name: 'Door models', route: 'door_models', type: 'door_model' },
                { name: 'Door types', route: 'door_types', type: 'door_type' },
                { name: 'Accessories', route: 'accessories', type: 'accessory' },
            ],
            iconClass: 'material-icons',
            iconName: 'new_releases',
            color: 'navy',
        },
        {
            name: 'Accounts',
            children: [
                { name: 'Users', route: 'users', shortcut: 'user/_', type: 'user' },
                { name: 'Roles', route: 'roles', shortcut: 'role/_', type: 'role' },
                { name: 'Activities', route: 'user_logins', type: 'user_login' },
            ],
            iconClass: 'material-icons',
            iconName: 'account_box',
            color: 'navy',
        },
        {
            name: 'Settings',
            children: [
                { name: 'Settings', route: 'settings', type: 'setting' },
                { name: 'Redirect URLs', route: 'redirect_urls', type: 'redirect_url' },
            ],
            iconClass: 'material-icons',
            iconName: 'settings',
            color: 'navy',
        }
    ],
    defaultItem: 'Content',
}
